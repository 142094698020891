import { all, takeLatest } from 'redux-saga/effects';
import {
  raProgramCreators,
  raProgramActions,
} from '../reducers/raProgram.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchRaPrograms } from '../../services/raisingAwarenessService';

const handleFetchAllRaPrograms = createAsyncSaga(
  raProgramCreators.fetchAllRaPrograms,
  fetchRaPrograms,
);

export function* raProgramSaga() {
  yield all([
    takeLatest(
      raProgramActions.FETCH_ALL_RA_PROGRAMS,
      handleFetchAllRaPrograms,
    ),
  ]);
}
