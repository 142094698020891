import { Button, Form, Input, notification, Select } from 'antd';
import AntTinymceInput from './antTinymceInput';
import MoveAppUrl from './moveAppUrl';
import React, { useCallback, useEffect, useState } from 'react';
import {
  fetchRaResultPage,
  patchRaResultPage,
  postRaResultPage,
} from '../../services/raisingAwarenessService';
import { CONFLICT, NO_MATCHING_DATA_FOUND } from '../util/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { treatmentNewsCreators } from '../../store/reducers/treatmentNews.reducer';
import { diseaseTypeCreators } from '../../store/reducers/diseaseType.reducer';
import { noticeCreators } from '../../store/reducers/notice.reducer';
import { dnaCreators } from '../../store/reducers/dna.reducer';
import { rssFeedCreators } from '../../store/reducers/rssFeed.reducer';
import { papProjectCreators } from '../../store/reducers/papProject.reducer';
import { raProgramCreators } from '../../store/reducers/raProgram.reducer';

const RaGeneForm = ({ raProjectId, pageId, hasAnsweredUser, isApp }) => {
  const dispatch = useDispatch();
  const { treatmentNews, notices, dnas, rssFeed, pap, ra } = useSelector(
    (state) => {
      return {
        treatmentNews: state.treatmentNewsReducer.treatmentNews.data?.map(
          (treatment) => ({
            ...treatment,
            key: treatment?.id,
          }),
        ),
        notices: state.noticeReducer.notices.data,
        dnas: state.dnaReducer.dna?.data,
        rssFeed: state.rssFeedReducer?.rssFeed?.data,
        pap: state.papProjectReducer?.papProject?.data?.data,
        ra: state.raProgramReducer?.raProgram?.data,
      };
    },
    shallowEqual,
  );

  const [content, setContent] = useState('');
  const [form] = Form.useForm();
  const isEditLimited = isApp || hasAnsweredUser;

  const getData = useCallback(async () => {
    if (!raProjectId || !pageId) return;
    const res = await fetchRaResultPage(raProjectId, pageId);
    form.setFieldsValue(res);
    setContent(res.content);
  }, [raProjectId, pageId]);

  useEffect(() => {
    getData();
    dispatch(
      treatmentNewsCreators.fetchAllTreatmentNews.request({
        params: '',
      }),
    );
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(noticeCreators.fetchNotices.request());
    dispatch(dnaCreators.fetchAllDnas.request());
    dispatch(rssFeedCreators.fetchAllRssFeeds.request());
    dispatch(papProjectCreators.fetchAllPapProjects.request());
    dispatch(raProgramCreators.fetchAllRaPrograms.request());
  }, []);

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onFinish = async (values) => {
    if (!window.confirm('저장하시겠습니까?')) return;
    const isPatch = !!pageId;

    const data = {
      conditions: null,
      cta: {
        type: 'DONE',
        title: null,
      },
      ...values,
      content,
      type: 'DNA_TEST_REPORT_RESULT',
    };

    try {
      if (isPatch) {
        await patchRaResultPage(raProjectId, pageId, data);
      } else {
        await postRaResultPage(raProjectId, data);
      }
      window.alert('저장되었습니다.');
      window.close();
    } catch (e) {
      let description = undefined;
      if (e.data) {
        if (e.data.status === CONFLICT) {
          description = (
            <>
              ${data.dnaTestReportResult === 'MATCHED' ? '적합' : '부적합'} 결과
              페이지가 이미 존재하는지,
              <br />
              또는 RA 프로그램이 진행중/완료 상태이거나 앱에 노출되어 있지
              않은지 확인해 주세요.
            </>
          );
        }
        if (e.data.status === NO_MATCHING_DATA_FOUND) {
          description = '존재하지 않는 질환 또는 페이지입니다.';
        }
      }
      notification.error({
        message: '저장에 실패했습니다.',
        description,
      });
    }
  };

  return (
    <Form
      name="gene"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      onFinish={onFinish}
      form={form}
      initialValues={{
        dnaTestReportResult: 'MATCHED',
        title: '',
        subtitle: '',
        content: '',
      }}
    >
      <Form.Item label="적합성 여부" name="dnaTestReportResult" required>
        <Select disabled={isEditLimited}>
          <Select.Option value="MATCHED">적합</Select.Option>
          <Select.Option value="NOT_MATCHED">부적합</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="제목"
        name="title"
        rules={[
          {
            required: true,
            message: '제목을 입력해주세요.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="부제목" name="subtitle">
        <Input />
      </Form.Item>
      <MoveAppUrl
        type="contents"
        treatmentNews={treatmentNews}
        notices={notices}
        dnas={dnas}
        rssFeed={rssFeed}
        pap={pap}
        ra={ra}
      />
      <Form.Item label="내용">
        <AntTinymceInput content={content} setContent={setContent} />
        <Input.TextArea rows={10} value={content} readOnly />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 4,
          span: 20,
        }}
      >
        <Button type="primary" htmlType="submit" style={{ width: 100 }}>
          저장
        </Button>
        <Button
          htmlType="button"
          style={{ width: 100, marginLeft: 8 }}
          onClick={onReset}
        >
          취소
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RaGeneForm;
