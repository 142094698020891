import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  notification,
  Input,
  Radio,
  Typography,
} from 'antd';

import '../../../public/css/window.css';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import WindowHeader from '../../components/windowHeader';
import ElementLoading from '../../components/elementLoading';
import RaQuestionForm from '../../components/raQuestionForm';
import RaIntroForm from '../../components/raIntroForm';
import RaResultForm from '../../components/raResultForm';
import RaGeneForm from '../../components/raGeneForm';
import { fetchRaQuestionVariables } from '../../../services/raisingAwarenessService';
import { RA_PAGE_TYPE } from '../../../util/raConstants';

const RaisingAwarenessPageWindow = () => {
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [variables, setVariables] = useState([]);

  // 쿼리패러미터
  const [pageType, setPageType] = useState(RA_PAGE_TYPE.INTRO);
  const [raisingAwarenessId, setRaisingAwarenessId] = useState(null);
  const [raisingAwarenessPageId, setRaisingAwarenessPageId] = useState(null);
  const [title, setTitle] = useState('');
  const [firstQuestion, setFirstQuestion] = useState('');
  const [hasAnsweredUser, setHasAnsweredUser] = useState(false);
  const [isApp, setIsApp] = useState(false);
  const isNew = windowId.includes('new');

  const setupBeforeUnloadListener = (data) => {
    const handler = (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    };
    window.addEventListener('beforeunload', handler);
    return handler;
  };

  const getData = useCallback(async () => {
    if (!raisingAwarenessId) return;
    const vars = await fetchRaQuestionVariables(raisingAwarenessId);
    setVariables(vars);
  }, [raisingAwarenessId]);

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    if (params.pageType) {
      setPageType(params.pageType);
    }
    setWindowId(params.id ? params.id : params.new);
    if (!params.raisingAwarenessId) {
      alert('잘못된 접근입니다.');
      window.close();
    }
    setRaisingAwarenessId(params.raisingAwarenessId);
    setHasAnsweredUser(params.hasAnsweredUser === 'true');
    setFirstQuestion(params.firstQuestion);
    setIsApp(params.isApp === 'true');
    if (params.id) {
      setRaisingAwarenessPageId(params.id);
    } else {
      setRaisingAwarenessPageId(null);
    }
    if (params.title) {
      setTitle(params.title);
    }
    const unloadListener = setupBeforeUnloadListener(`close ${windowId}`);
    setIsLoading(false);

    return () => {
      window.removeEventListener('beforeunload', unloadListener);
    };
  }, [search, windowId]);

  useEffect(() => {
    getData();
  }, [raisingAwarenessId, raisingAwarenessPageId]);

  if (isLoading) return <ElementLoading type="RA 문항 / 답변 및 페이지 관리" />;
  return (
    <>
      <WindowHeader title="RA 문항 / 답변 및 페이지 관리" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form name="wrapper" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
            <Form.Item label="종속 RA 프로그램">
              <Input disabled value={title} />
            </Form.Item>
            <Form.Item label="페이지 타입">
              <Radio.Group
                onChange={(e) => setPageType(e.target.value)}
                value={pageType}
                disabled={!isNew}
              >
                <Radio value={RA_PAGE_TYPE.INTRO}>소개 페이지</Radio>
                <Radio value={RA_PAGE_TYPE.SURVEY}>문항 / 답변 페이지</Radio>
                <Radio value={RA_PAGE_TYPE.RESULT}>결과 페이지</Radio>
                <Radio value={RA_PAGE_TYPE.DNA}>
                  유전자 적합성 결과 페이지
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
          {pageType === RA_PAGE_TYPE.INTRO && (
            <RaIntroForm
              raProjectId={raisingAwarenessId}
              pageId={raisingAwarenessPageId}
              hasAnsweredUser={hasAnsweredUser}
              isApp={isApp}
              firstQuestion={firstQuestion}
            />
          )}
          {pageType === RA_PAGE_TYPE.SURVEY && (
            <RaQuestionForm
              raProjectId={raisingAwarenessId}
              pageId={raisingAwarenessPageId}
              variables={variables}
              hasAnsweredUser={hasAnsweredUser}
              isApp={isApp}
            />
          )}
          {pageType === RA_PAGE_TYPE.RESULT && (
            <RaResultForm
              raProjectId={raisingAwarenessId}
              pageId={raisingAwarenessPageId}
              variables={variables}
              hasAnsweredUser={hasAnsweredUser}
              isApp={isApp}
            />
          )}
          {pageType === RA_PAGE_TYPE.DNA && (
            <RaGeneForm
              raProjectId={raisingAwarenessId}
              pageId={raisingAwarenessPageId}
              hasAnsweredUser={hasAnsweredUser}
              isApp={isApp}
            />
          )}
        </Col>
      </Row>
    </>
  );
};
export default RaisingAwarenessPageWindow;
