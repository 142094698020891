// set file input event (image upload)
import { useState, useEffect } from 'react';
import {
  getSignedUrl,
  changeAccessRangeToPublic,
} from '../../services/fileUploadService';
import { useFetch } from '../../hooks/useRequest';

export function useAntFileUpload(fileInputEvent) {
  const [done, setDone] = useState(false);
  const [publicUrl, setPublicUrl] = useState(null);
  const [filePath, setFilePath] = useState(null);
  const {
    done: changeDone,
    call: changeUrlToPublic,
    initialize: initializeDone,
  } = useFetch(null, changeAccessRangeToPublic, { filePath });

  const {
    done: fetchSignedUrlDone,
    data: signedUrlData,
    call: fetchSignedUrl,
    initializeData: initializeSignedUrl,
  } = useFetch(null, getSignedUrl, {
    params: {
      functionType: 'PUT',
      fileName: fileInputEvent?.file?.name,
      contentType: fileInputEvent?.file?.type,
    },
  });

  const onUploadFinish = (newSignedUrlUploadData) => {
    setFilePath(newSignedUrlUploadData?.filePath);
  };

  useEffect(() => {
    if (filePath) {
      changeUrlToPublic().then(() => {
        setPublicUrl(signedUrlData.publicUrl);
      });
      setFilePath(null);
    }
  }, [filePath, changeDone, changeUrlToPublic]);

  useEffect(() => {
    if (changeDone) {
      setDone(true);
      initializeSignedUrl();
      initializeDone();
    }
  }, [changeDone, initializeDone, initializeSignedUrl]);

  const onUploadError = () => {
    alert('이미지 업로드에 실패했습니다!');
  };

  const imageUpload = () => {
    fetchSignedUrl();
  };
  useEffect(() => {
    if (fetchSignedUrlDone && signedUrlData) {
      const data = signedUrlData;
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', data.signedUrl);
      xhr.onload = () => {
        onUploadFinish(data);
      };
      xhr.onerror = onUploadError;
      xhr.setRequestHeader('Content-Type', fileInputEvent?.file?.type);
      xhr.send(fileInputEvent?.file?.originFileObj);
    }
  }, [fileInputEvent, signedUrlData, fetchSignedUrlDone]);

  const initialize = (url) => {
    setPublicUrl(url ?? null);
    setDone(false);
  };
  return { publicUrl, done, imageUpload, initialize };
}
