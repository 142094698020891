import { all } from 'redux-saga/effects';
import { noticeSaga } from './notice.saga';
import { authSaga } from './auth.saga';
import { notificationSaga } from './notification.saga';
import { treatmentNewsSaga } from './treatmentNews.saga';
import { userSaga } from './user.saga';
import { diseaseTypeSaga } from './diseaseType.saga';
import { dnaSaga } from './dna.saga';
import { fileUploadSaga } from './fileUpload.saga';
import { dictionarySaga } from './dictionary.saga';
import { healthProfileSaga } from './healthProfile.saga';
import { tagSaga } from './tag.saga';
import { roleSaga } from './role.saga';
import { permissionSaga } from './permission.saga';
import { treatmentTypeSaga } from './treatmentType.saga';
import { mutationDnaSaga } from './mutationDna.saga';
import { mutationDnaReportSaga } from './mutationDnaReport.saga';
import { mutationDnaReportRegisterRequestSaga } from './mutationDnaReportRegisterRequest.saga';
import { dashboardSaga } from './dashboard.saga';
import { hospitalExaminationSaga } from './hospitalExamination.saga';
import { supportVersionSaga } from './supportVersion.saga';
import { ipAllowSaga } from './ipAllow.saga';
import { geneResearchSaga } from './geneResearch.saga';
import { doctorProfileSaga } from './doctorProfile.saga';
import { remoteConfigSaga } from './remoteConfig.saga';
import { patientGroupSaga } from './patientGroup.saga';
import { rssFeedSaga } from './rssFeed.saga';
import { topicTagSaga } from './topicTag.saga';
import { translateWordSaga } from './translateWord.saga';
import { entryModalSaga } from './entryModal.saga';
import { papProjectSaga } from './papProject.saga';
import { raProgramSaga } from './raProgram.saga';

export default function* rootSaga() {
  yield all([
    userSaga(),
    notificationSaga(),
    treatmentNewsSaga(),
    noticeSaga(),
    authSaga(),
    diseaseTypeSaga(),
    dnaSaga(),
    fileUploadSaga(),
    dictionarySaga(),
    healthProfileSaga(),
    tagSaga(),
    topicTagSaga(),
    roleSaga(),
    permissionSaga(),
    treatmentTypeSaga(),
    mutationDnaSaga(),
    mutationDnaReportSaga(),
    mutationDnaReportRegisterRequestSaga(),
    dashboardSaga(),
    hospitalExaminationSaga(),
    supportVersionSaga(),
    ipAllowSaga(),
    geneResearchSaga(),
    doctorProfileSaga(),
    remoteConfigSaga(),
    patientGroupSaga(),
    rssFeedSaga(),
    translateWordSaga(),
    entryModalSaga(),
    papProjectSaga(),
    raProgramSaga(),
  ]);
}
