import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Layout,
  Table,
  Input,
  Row,
  notification,
  Typography,
  Space,
  Select,
} from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import {
  HEALTH_PROFILE_ACCOUNT_TYPES,
  HEALTH_PROFILE_GENDER,
} from '../../../util/healthProfileConstants';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { SearchOutlined } from '@ant-design/icons';
import {
  deleteRaUserResponses,
  fetchRaUserResponses,
} from '../../../services/raisingAwarenessService';

const RaisingAwarenessUsers = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { raUserPagination, diseaseTypeInfo } = useSelector((state) => {
    return {
      raUserPagination: state?.paginationReducer?.raUserPagination,
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data ?? [],
    };
  }, shallowEqual);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  const diseaseTypeList = useMemo(() => {
    const arr = [
      {
        label: '전체 질환',
        value: 'all',
      },
      ...diseaseTypeInfo?.map((d) => ({
        label: d.krName,
        value: d.id,
      })),
    ];
    return arr;
  }, [diseaseTypeInfo]);

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const confirmRef = useRef(() => {});

  const getDiseaseTypeList = useCallback(() => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);

  const getData = useCallback(async () => {
    setLoading(true);
    const projectId = state?.raisingAwarenessId;
    const data = await fetchRaUserResponses(projectId);
    setDataSource(data);
    setLoading(false);
  }, [dispatch, state]);

  useEffect(() => {
    getData();
  }, [getData]);
  useEffect(getDiseaseTypeList, [getDiseaseTypeList]);

  const { Search } = Input;
  const { Content } = Layout;

  const handleMoveDetailPage = useCallback(
    ({ userId }) => () =>
      history.push('/userDetail', {
        userId,
        dataKey: 'raisingAwarenessSelected',
      }),
    [history],
  );

  const CompletedTag = ({ text }) => {
    return (
      <div
        className="tagWrapper"
        style={{
          color: text === 'DONE' ? '#1890ff' : '#979797',
        }}
      >
        {text}
      </div>
    );
  };

  const onReset = async () => {
    if (selectedUsers.length === 0) return;
    if (window.confirm('선택한 유저들의 응답을 초기화 하시겠습니까?')) {
      try {
        await deleteRaUserResponses(state?.raisingAwarenessId, selectedUsers);
        notification.success({
          message: '선택된 유저들의 응답 상태를 초기화했습니다.',
          key: 'resetComplete',
        });
        getData();
        setSelectedUsers([]);
      } catch (e) {
        notification.error({
          message: '선택된 유저들의 응답 상태 초기화에 실패했습니다.',
          key: 'resetFail',
        });
        throw e;
      }
    }
  };

  const columns = [
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <CompletedTag
          text={
            status === 'READY'
              ? '진행 전'
              : status === 'ONGOING'
              ? '진행 중'
              : '완료'
          }
        />
      ),
      filters: [
        {
          text: '완료',
          value: true,
        },
        {
          text: '진행중',
          value: false,
        },
      ],
      filteredValue: raUserPagination.filter?.isComplete || null,
      onFilter: (value, record) => record.isComplete === value,
    },
    {
      title: '업데이트일',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text, record) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
      sorter: {
        compare: (a, b) => a?.updatedAt?.localeCompare(b?.updatedAt),
      },
    },
    {
      title: '계정',
      dataIndex: 'email',
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: raUserPagination.filter?.email || null,
      onFilter: (search, { email, name, phone }) =>
        [email, name, phone].some((value) => value?.includes(search)),
      render: (text, record) =>
        text ? (
          <>
            {text}
            <Button
              type="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={handleMoveDetailPage(record)}
            >
              이동
            </Button>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      filteredValue: raUserPagination.filter?.gender || [],
      onFilter: (value, record) => record.gender === value,
      filters: Object.entries(HEALTH_PROFILE_GENDER).map(([key, value]) => ({
        text: value,
        value: key,
      })),
      render: (gender) => HEALTH_PROFILE_GENDER[gender] ?? '-',
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypes',
      key: 'diseaseTypes',
      render: (diseaseTypes) =>
        diseaseTypes?.map((d) => d.name)?.join(', ') ?? '-',
      filters: diseaseTypeList,
      filteredValue: raUserPagination.filter.diseaseTypes || null,
      onFilter: (value, record) => {
        const isDiseaseMatched = record.diseaseTypes
          .map((d) => d.id)
          .includes(value);
        const isAllDiseaseMatched =
          value === 'all' && record.diseaseTypes.length === 0;
        return isDiseaseMatched || isAllDiseaseMatched;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Select
            style={{
              marginRight: 8,
              width: 250,
            }}
            placement="bottomLeft"
            defaultValue={raUserPagination.filter.diseaseTypes ?? null}
            value={selectedKeys}
            showSearch
            mode="multiple"
            placeholder="질환을 선택하세요"
            options={diseaseTypeList}
            filterOption={(input, { label }) =>
              label.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => setSelectedKeys(e)}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters()}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: '결과 페이지',
      dataIndex: 'resultPageTitle',
      key: 'resultPageTitle',
    },
    {
      title: '유전자 적합성 결과',
      dataIndex: 'dnaTestReportResult',
      key: 'dnaTestReportResult',
      render: (dnaTestReportResult) => {
        if (!dnaTestReportResult) return '-';
        return dnaTestReportResult === 'MATCHED' ? '적합' : '부적합';
      },
    },
    {
      title: '계정 유형',
      dataIndex: 'accountType',
      key: 'accountType',
      render: (accountType) => HEALTH_PROFILE_ACCOUNT_TYPES[accountType] ?? '-',
      filteredValue: raUserPagination.filter?.accountType || [],
      onFilter: (value, record) => record.accountType === value,
      filters: Object.entries(HEALTH_PROFILE_ACCOUNT_TYPES).map(
        ([key, value]) => ({
          text: value,
          value: key,
        }),
      ),
    },
    {
      title: '14세 미만',
      dataIndex: 'isUnder14',
      key: 'isUnder14',
      render: (isUnder14) => <>{isUnder14 ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: raUserPagination.filter?.isUnder14 || [],
      onFilter: (value, record) => record.isUnder14 === value,
    },
    {
      title: '테스트ID',
      dataIndex: 'isTest',
      key: 'isTest',
      render: (isTest) => <>{isTest ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: raUserPagination.filter?.isTest || [],
      onFilter: (value, record) => record.isTest === value,
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="raisingAwareness" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="정보"
            subTitle="RA 답변 완료/진행중인 유저"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Typography.Title level={4}>{state.title}</Typography.Title>
            <Row>
              <Button
                type="primary"
                onClick={() => history.push('/raisingAwareness')}
              >
                RA 목록으로 돌아가기
              </Button>
              <Button
                danger
                style={{ marginLeft: 8 }}
                onClick={onReset}
                disabled={selectedUsers.length === 0}
              >
                답변 초기화
              </Button>
            </Row>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
              }}
              defaultValue={raUserPagination.text}
            />
            <div className="searchResult">검색결과 {total}개</div>
            <Table
              rowKey={(record) => record.userId}
              columns={columns}
              dataSource={dataSource}
              pagination={{
                onChange: (page) => {
                  setPageUpdate(page);
                },
                current: pageUpdate,
                defaultPageSize: 20,
                showSizeChanger: true,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(pagination, filters, sorter, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (raUserPagination.page = pagination?.current),
                    (raUserPagination.filter = filters),
                    (raUserPagination.total = extra?.currentDataSource?.length),
                  ),
                );
                setTotal(extra?.currentDataSource?.length);
              }}
              size="small"
              rowSelection={{
                type: 'checkbox',
                onChange: (selectedRowKeys) => {
                  setSelectedUsers(selectedRowKeys);
                },
              }}
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default RaisingAwarenessUsers;
