import { useEffect, useState } from 'react';
import { getFileInfo, postFileInfo } from '../../services/fileUploadService';

/**
 * nestJS api를 사용하여 파일을 업로드합니다.
 * @param {object} fileInputEvent - 파일 업로드 이벤트 객체
 * @param {string} type - 파일 구분 (RA_ICON, ...)
 */
const useUploadFile = (fileInputEvent, type) => {
  const [done, setDone] = useState(false);
  const [publicUrl, setPublicUrl] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [signedUrlData, setSignedUrlData] = useState(null);
  const fetchSignedUrlDone = !!signedUrlData;

  const onUploadError = (e) => {
    alert('이미지 업로드에 실패했습니다!');
  };

  const imageUpload = async ({ file }) => {
    const res = await postFileInfo({
      name: file.name,
      contentType: file.type,
      volume: file.size,
      type,
    });
    setFileId(res.id);
    setSignedUrlData(res);
  };

  useEffect(() => {
    if (fetchSignedUrlDone && signedUrlData) {
      const data = signedUrlData;
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', data.url);
      xhr.onerror = onUploadError;
      xhr.setRequestHeader('Content-Type', fileInputEvent?.file?.type);
      xhr.send(fileInputEvent?.file?.originFileObj);
      xhr.onload = async (e) => {
        const res = await getFileInfo(fileId);
        setPublicUrl(res.url);
        setDone(true);
      };
    }
  }, [fileInputEvent, signedUrlData, fetchSignedUrlDone]);

  const initialize = (url) => {
    setPublicUrl(url ?? null);
    setDone(false);
  };
  return { publicUrl, fileId, done, imageUpload, initialize };
};

export default useUploadFile;
