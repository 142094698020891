import { Button, Form, Input, notification, Radio, Typography } from 'antd';
import AntTinymceInput from './antTinymceInput';
import MoveAppUrl from './moveAppUrl';
import React, { useCallback, useEffect, useState } from 'react';
import {
  fetchRaIntroPage,
  patchRaIntroPage,
  postRaIntroPage,
} from '../../services/raisingAwarenessService';
import { CONFLICT, NO_MATCHING_DATA_FOUND } from '../util/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { raProgramCreators } from '../../store/reducers/raProgram.reducer';
import { papProjectCreators } from '../../store/reducers/papProject.reducer';
import { rssFeedCreators } from '../../store/reducers/rssFeed.reducer';
import { dnaCreators } from '../../store/reducers/dna.reducer';
import { noticeCreators } from '../../store/reducers/notice.reducer';
import { diseaseTypeCreators } from '../../store/reducers/diseaseType.reducer';
import { treatmentNewsCreators } from '../../store/reducers/treatmentNews.reducer';

const RaIntroForm = ({
  raProjectId,
  pageId,
  isApp,
  hasAnsweredUser,
  firstQuestion,
}) => {
  const dispatch = useDispatch();
  const { treatmentNews, notices, dnas, rssFeed, pap, ra } = useSelector(
    (state) => {
      return {
        treatmentNews: state.treatmentNewsReducer.treatmentNews.data?.map(
          (treatment) => ({
            ...treatment,
            key: treatment?.id,
          }),
        ),
        notices: state.noticeReducer.notices.data,
        dnas: state.dnaReducer.dna?.data,
        rssFeed: state.rssFeedReducer?.rssFeed?.data,
        pap: state.papProjectReducer?.papProject?.data?.data,
        ra: state.raProgramReducer?.raProgram?.data,
      };
    },
    shallowEqual,
  );

  const [content, setContent] = useState('');
  const [form] = Form.useForm();
  const isEditLimited = isApp || hasAnsweredUser;

  const getData = useCallback(async () => {
    if (!raProjectId || !pageId) return;
    const res = await fetchRaIntroPage(raProjectId, pageId);
    form.setFieldsValue(res);
    setContent(res.content);
  }, [raProjectId]);

  useEffect(() => {
    getData();
    dispatch(
      treatmentNewsCreators.fetchAllTreatmentNews.request({
        params: '',
      }),
    );
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(noticeCreators.fetchNotices.request());
    dispatch(dnaCreators.fetchAllDnas.request());
    dispatch(rssFeedCreators.fetchAllRssFeeds.request());
    dispatch(papProjectCreators.fetchAllPapProjects.request());
    dispatch(raProgramCreators.fetchAllRaPrograms.request());
  }, []);

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onFinish = async (values) => {
    if (!window.confirm('저장하시겠습니까?')) return;
    const isPatch = !!pageId;

    const data = {
      ...values,
      content,
    };
    if (data.cta.type === 'DNA_TEST_REPORT_RESULT') {
      data.cta.title = null;
    }

    try {
      if (isPatch) {
        await patchRaIntroPage(raProjectId, pageId, data);
      } else {
        await postRaIntroPage(raProjectId, data);
      }
      window.alert('저장되었습니다.');
      window.close();
    } catch (e) {
      let description = undefined;
      if (e.data) {
        if (e.data.status === CONFLICT) {
          description =
            '소개 페이지가 이미 존재하거나, RA 프로그램이 진행중/완료 상태이거나 앱에 노출중이지 않은지 확인해주세요.';
        }
        if (e.data.status === NO_MATCHING_DATA_FOUND) {
          description = '존재하지 않는 페이지입니다.';
        }
      }
      notification.error({
        message: '저장에 실패했습니다.',
        description,
      });
    }
  };

  return (
    <Form
      name="intro"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      onFinish={onFinish}
      form={form}
      initialValues={{
        title: '',
        subtitle: '',
        content: '',
        cta: {
          type: 'SURVEY',
          title: '',
        },
      }}
    >
      <Form.Item
        label="제목"
        name="title"
        rules={[
          {
            required: true,
            message: '제목을 입력해주세요.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="부제목" name="subtitle">
        <Input />
      </Form.Item>
      <MoveAppUrl
        type="contents"
        treatmentNews={treatmentNews}
        notices={notices}
        dnas={dnas}
        rssFeed={rssFeed}
        pap={pap}
        ra={ra}
      />
      <Form.Item label="문항 제목 및 설명">
        <AntTinymceInput content={content} setContent={setContent} />
        <Input.TextArea rows={10} value={content} readOnly />
      </Form.Item>
      <Form.Item label="CTA 연결" name={['cta', 'type']}>
        <Radio.Group disabled={isEditLimited}>
          <Radio value="SURVEY">문항 / 답변 페이지</Radio>
          <Radio value="DNA_TEST_REPORT_RESULT">유전자 검사 결과 등록</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.cta.type !== currentValues.cta.type
        }
      >
        {({ getFieldValue }) => (
          <>
            {getFieldValue(['cta', 'type']) === 'SURVEY' && (
              <>
                <Form.Item
                  label="CTA 타이틀"
                  name={['cta', 'title']}
                  rules={[
                    {
                      required: true,
                      message: 'CTA 타이틀을 입력해주세요.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="CTA 연결 문항">
                  <Input disabled value={firstQuestion} />
                </Form.Item>
              </>
            )}
            {getFieldValue(['cta', 'type']) === 'DNA_TEST_REPORT_RESULT' && (
              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 20,
                }}
                style={{ marginTop: -24 }}
              >
                <Typography.Text type="success">
                  본 소개 페이지의 CTA는 유전자 검사 결과 등록 페이지로
                  연결됩니다.
                </Typography.Text>
              </Form.Item>
            )}
          </>
        )}
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 4,
          span: 20,
        }}
      >
        <Button type="primary" htmlType="submit" style={{ width: 100 }}>
          저장
        </Button>
        <Button
          htmlType="button"
          style={{ width: 100, marginLeft: 8 }}
          onClick={onReset}
        >
          취소
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RaIntroForm;
