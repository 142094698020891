import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Checkbox,
  DatePicker,
  notification,
  InputNumber,
} from 'antd';
import '../../../public/css/window.css';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from 'moment';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import WindowHeader from '../../components/windowHeader';

import { HEALTH_PROFILE_ACCOUNT_TYPES } from '../../../util/healthProfileConstants';
import {
  fetchHealthProfile,
  patchHealthProfile,
  postHealthProfile,
} from '../../../services/healthProfileService';
import { CONFLICT, NO_MATCHING_DATA_FOUND } from '../../util/utils';
import { MinusCircleOutlined } from '@ant-design/icons';

const INITIAL_VALUES = {
  title: '',
  description: null,
  diseaseTypeIds: [],
  accountTypes: Object.keys(HEALTH_PROFILE_ACCOUNT_TYPES),
  shareLink: null,
};

const HealthProfileWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { diseaseTypeInfo } = useSelector((state) => {
    return {
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data
        ? state.diseaseTypeReducer.diseaseTypes.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
    };
  }, shallowEqual);

  const [form] = Form.useForm();
  const [selectDisease, setSelectDisease] = useState(false);
  const [windowId, setWindowId] = useState(null);
  const [healthProfileId, setHealthProfileId] = useState(null);
  const [hasAnsweredUser, setHasAnsweredUser] = useState(false);

  const setupBeforeUnloadListener = (data) => {
    const callback = (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    };
    window.addEventListener('beforeunload', callback);
    return callback;
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setHealthProfileId(params.id);
    } else {
      setHealthProfileId(null);
    }
    setHasAnsweredUser(params.hasAnsweredUser === 'true');
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    const unloadHandler = setupBeforeUnloadListener(`close ${windowId}`);
    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [dispatch, search, windowId]);

  const getData = useCallback(async () => {
    const res = await fetchHealthProfile(healthProfileId);
    form.setFieldsValue({
      ...res,
      diseaseTypeIds: res.diseaseTypes.map((diseaseType) => diseaseType.id),
      diseaseTypes: undefined,
    });
    setSelectDisease(res.diseaseTypes.length > 0);
  }, [form, healthProfileId]);

  useEffect(() => {
    if (healthProfileId) {
      getData();
    }
  }, [getData, healthProfileId]);

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
      createdAt: undefined,
      isAgeFilterEnabled: values.ageFilterConditions?.length > 0,
    };
    try {
      if (window.confirm('저장하시겠습니까?')) {
        if (healthProfileId) {
          const patchData = {
            ...data,
            shareLink: values.shareLink || null,
          };
          if (hasAnsweredUser) {
            patchData.diseaseTypeIds = undefined;
            patchData.accountTypes = undefined;
          }
          await patchHealthProfile(healthProfileId, patchData);
        } else {
          // POST
          await postHealthProfile(data);
        }
        alert('성공적으로 저장되었습니다.');
        window.close();
      }
    } catch (e) {
      let description = undefined;
      if (e.data) {
        if (e.data.status === CONFLICT) {
          description =
            '이미 설문을 진행한 유저가 있어 제목만 수정 가능합니다.';
        }
        if (e.data.status === NO_MATCHING_DATA_FOUND) {
          description = '존재하지 않는 질환 또는 설문입니다.';
        }
      }
      notification.error({
        message: '오류가 발생했습니다.',
        description: description,
      });
      if (!description) throw e;
    }
  };

  return (
    <>
      <WindowHeader title="설문" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={onSubmit}
            initialValues={INITIAL_VALUES}
          >
            <Form.Item label="질환 선택">
              <Checkbox
                checked={selectDisease}
                onChange={(e) => {
                  setSelectDisease(e.target.checked);
                  if (!e.target.checked)
                    form.setFieldsValue({ diseaseTypeIds: [] });
                }}
                disabled={hasAnsweredUser}
              >
                직접 선택하기 (질환 선택을 체크하지 않으면 질환이 전체
                선택됩니다.)
              </Checkbox>
            </Form.Item>
            <Form.Item
              label="질환"
              required
              name="diseaseTypeIds"
              rules={[
                {
                  validator(rule, value) {
                    if (selectDisease && value.length === 0) {
                      return Promise.reject('질환을 선택해주세요.');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Select
                disabled={!selectDisease || hasAnsweredUser}
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="질환 태그를 선택해주세요"
                filterOption={(input, option) =>
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.key.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
              >
                {diseaseTypeInfo?.map((diseaseType) => (
                  <Select.Option
                    key={diseaseType?.krName}
                    id={diseaseType?.krName}
                    value={diseaseType?.id}
                  >
                    {diseaseType.krName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="설문 제목"
              name="title"
              rules={[{ required: true, message: '설문 제목을 입력해주세요.' }]}
            >
              <Input placeholder="설문 제목을 입력해주세요." />
            </Form.Item>
            <Form.Item label="설문 설명" name="description">
              <Input placeholder="설문 설명을 입력해주세요." />
            </Form.Item>
            <Form.Item
              label="생성일"
              name="createdAt"
              getValueProps={(v) => ({
                value: moment(v),
              })}
            >
              <DatePicker disabled />
            </Form.Item>
            <Form.Item
              label="설문 대상 계정 유형"
              name="accountTypes"
              getValueProps={(v) => ({
                value: v.sort((a, b) => a - b),
              })}
              rules={[
                {
                  validator(rule, value) {
                    if (value.length === 0) {
                      return Promise.reject(
                        '설문 대상 계정 유형을 선택해주세요.',
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Checkbox.Group disabled={hasAnsweredUser}>
                {Object.entries(HEALTH_PROFILE_ACCOUNT_TYPES).map(
                  ([key, value]) => (
                    <Checkbox key={key} value={key}>
                      {value}
                    </Checkbox>
                  ),
                )}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              label="설문 대상 나이 설정"
              tooltip={
                <>
                  설정한 나이의 유저에게만 설문이 노출됩니다.
                  <br />
                  나이 설정 조건은 논리적으로 유효해야 하며, 조건에 충돌이
                  없도록 설정해야 합니다. (예: 만 나이 3세 이상 4세 이하)
                </>
              }
            >
              <Form.List name="ageFilterConditions">
                {(fields, { add, remove }) => (
                  <>
                    <Form.Item>
                      <Button
                        onClick={() =>
                          add({
                            operator: '>=',
                            ...(fields.length > 0 && {
                              logic: 'AND',
                            }),
                          })
                        }
                      >
                        추가
                      </Button>
                    </Form.Item>
                    {fields.map(({ key, name }) => (
                      <Row key={key} style={{ gap: 8 }}>
                        <Button
                          icon={<MinusCircleOutlined />}
                          onClick={() => remove(name)}
                          type="text"
                        />
                        {name > 0 && (
                          <Form.Item name={[name, 'logic']}>
                            <Select style={{ width: 80 }}>
                              <Select.Option value="AND">AND</Select.Option>
                              <Select.Option value="OR">OR</Select.Option>
                            </Select>
                          </Form.Item>
                        )}
                        <Form.Item
                          key={key}
                          label="만 나이"
                          name={[name, 'value']}
                          rules={[
                            {
                              required: true,
                              message: '만 나이를 입력해주세요.',
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder="숫자 입력"
                            min={0}
                            maxLength={3}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                        <Form.Item name={[name, 'operator']}>
                          <Select style={{ width: '100%' }}>
                            <Select.Option value=">=">이상</Select.Option>
                            <Select.Option value="<=">이하</Select.Option>
                            <Select.Option value=">">초과</Select.Option>
                            <Select.Option value="<">미만</Select.Option>
                            <Select.Option value="=">일치</Select.Option>
                          </Select>
                        </Form.Item>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>
            </Form.Item>
            <Form.Item label="공유 링크" name="shareLink">
              <Input placeholder="저장시 자동으로 생성됩니다." readOnly />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: 100, marginRight: 8 }}
              >
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default HealthProfileWindow;
