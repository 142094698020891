import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout, Table, Input, notification, Button } from 'antd';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import { HEALTH_PROFILE_ACCOUNT_TYPES } from '../../../util/healthProfileConstants';
import moment from 'moment';
import { fetchRaDnaReports } from '../../../services/raisingAwarenessService';

const STATUS = {
  READY: '확인 중',
  CANCELED: '등록 실패',
  MATCHED: '적합',
  NOT_MATCHED: '부적합',
};

const raisingAwarenessRegisterRequest = () => {
  const dispatch = useDispatch();
  const { raRegisterPagination } = useSelector((state) => {
    return {
      raRegisterPagination: state?.paginationReducer?.raRegisterPagination,
    };
  });
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const confirmRef = useRef();
  const history = useHistory();

  const getData = useCallback(async () => {
    setLoading(true);
    const res = await fetchRaDnaReports();
    setDataSource(res);
    setLoading(false);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const handleMoveDetailPage = (record) => {
    history.push(`/userDetail`, { userId: record.userId });
  };

  const columns = [
    {
      title: '계정',
      dataIndex: 'email',
      width: 180,
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: raRegisterPagination.filter?.email || null,
      onFilter: (search, { email, name, phone, diseaseType }) =>
        [email, name, phone, diseaseType?.krName].some((value) =>
          value?.includes(search),
        ),
      render: (text, record) => (
        <>
          {text}
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => handleMoveDetailPage(record)}
          >
            이동
          </Button>
        </>
      ),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 65,
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      width: 110,
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
      render: (phone) => (
        <>{phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}</>
      ),
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypes',
      key: 'diseaseTypes',
      width: 110,
      sorter: {
        compare: (a, b) =>
          a?.diseaseTypes[0]?.name?.localeCompare(b?.diseaseTypes[0]?.name),
      },
      render: (diseaseTypes) => <>{diseaseTypes[0]?.name}</>,
    },
    {
      title: '계정 유형',
      dataIndex: 'accountType',
      key: 'accountType',
      width: 105,
      sorter: {
        compare: (a, b) => a?.accountType?.localeCompare(b?.accountType),
      },
      filters: Object.entries(HEALTH_PROFILE_ACCOUNT_TYPES).map(
        ([key, value]) => ({
          text: value,
          value: key,
        }),
      ),
      filteredValue: raRegisterPagination.filter?.accountType || null,
      onFilter: (value, record) => record.accountType.includes(value),
      render: (accountType) => HEALTH_PROFILE_ACCOUNT_TYPES[accountType],
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 80,
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
      render: (createdAt) =>
        createdAt ? moment(createdAt).format('YY-MM-DD') : '',
    },
    {
      title: '등록 상태',
      dataIndex: 'status',
      key: 'status',
      width: 105,
      sorter: {
        compare: (a, b) => a?.status?.localeCompare(b?.status),
      },
      filters: [
        { text: '확인 중', value: 'READY' },
        { text: '등록 실패', value: 'CANCELED' },
        { text: '적합', value: 'MATCHED' },
        { text: '부적합', value: 'NOT_MATCHED' },
      ],
      filteredValue: raRegisterPagination.filter?.status || null,
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => STATUS[status],
    },
    {
      title: '테스트ID',
      dataIndex: 'isTest',
      key: 'isTest',
      width: 90,
      render: (isTest) => <>{isTest === true ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: raRegisterPagination.filter?.isTest || null,
      onFilter: (value, record) => record.isTest === value,
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link="raisingAwarenessRegisterRequest" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="데이터"
            subTitle="등록_RA 유전자 검사"
            className="white-bg"
          />
          <Layout.Content className="site-layout-background contentStyle">
            <Input.Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (raRegisterPagination.text = value),
                  ),
                );
              }}
              defaultValue={raRegisterPagination.text}
            />

            <div className="searchResult">
              {dataSource && `검색결과 ${raRegisterPagination.total}개`}
            </div>

            <Table
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={dataSource}
              loading={loading}
              pagination={{
                onChange: (page) => {
                  dispatch(
                    paginationCreators.setValue(
                      (raRegisterPagination.page = page),
                    ),
                  );
                },
                current: raRegisterPagination.page,
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(pagination, filters, sorter, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (raRegisterPagination.page = pagination?.current),
                    (raRegisterPagination.filter = filters),
                    (raRegisterPagination.total =
                      extra?.currentDataSource?.length),
                  ),
                );
              }}
              size="small"
              bordered
            />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default raisingAwarenessRegisterRequest;
