import { combineReducers } from 'redux';

import windowReducer from './window.reducer';
import noticeReducer from './notice.reducer';
import authReducer from './auth.reducer';
import notificationReducer from './notification.reducer';
import treatmentNewsReducer from './treatmentNews.reducer';
import userReducer from './user.reducer';
import diseaseTypeReducer from './diseaseType.reducer';
import treatmentStudyReducer from './treatmentStudy.reducer';
import dnaReducer from './dna.reducer';
import fileUploadReducer from './fileUpload.reducer';
import dictionaryReducer from './dictionary.reducer';
import healthProfileReducer from './healthProfile.reducer';
import tagReducer from './tag.reducer';
import roleReducer from './role.reducer';
import permissionReducer from './permission.reducer';
import treatmentTypeReducer from './treatmentType.reducer';
import mutationDnaReducer from './mutationDna.reducer';
import mutationDnaReportReducer from './mutationDnaReport.reducer';
import mutationDnaReportRegisterRequestReducer from './mutationDnaReportRegisterRequest.reducer';
import treatmentStudyTimelineReducer from './treatmentStudyTimeline.reducer';
import dashboardReducer from './dashboard.reducer';
import hospitalExaminationReducer from './hospitalExamination.reducer';
import supportVersionReducer from './supportVersion.reducer';
import ipAllowReducer from './ipAllow.reducer';
import paginationReducer from './pagination.reducer';
import geneResearchReducer from './geneResearch.reducer';
import doctorProfileReducer from './doctorProfile.reducer';
import patientGroupReducer from './patientGroup.reducer';
import remoteConfigReducer from './remoteConfig.reducer';
import rssFeedReducer from './rssFeed.reducer';
import translateWordReducer from './translateWord.reducer';
import entryModalReducer from './entryModal.reducer';
import papProjectReducer from './papProject.reducer';
import raProgramReducer from './raProgram.reducer';

export default combineReducers({
  windowReducer,
  userReducer,
  noticeReducer,
  notificationReducer,
  treatmentNewsReducer,
  authReducer,
  diseaseTypeReducer,
  treatmentStudyReducer,
  dnaReducer,
  fileUploadReducer,
  dictionaryReducer,
  healthProfileReducer,
  tagReducer,
  roleReducer,
  permissionReducer,
  treatmentTypeReducer,
  mutationDnaReducer,
  mutationDnaReportReducer,
  mutationDnaReportRegisterRequestReducer,
  treatmentStudyTimelineReducer,
  dashboardReducer,
  hospitalExaminationReducer,
  supportVersionReducer,
  ipAllowReducer,
  paginationReducer,
  geneResearchReducer,
  doctorProfileReducer,
  patientGroupReducer,
  remoteConfigReducer,
  rssFeedReducer,
  translateWordReducer,
  entryModalReducer,
  papProjectReducer,
  raProgramReducer,
});
