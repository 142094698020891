import { Button, Checkbox, Form, Input, notification, Row, Select } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { HEALTH_PROFILE_QUESTION_TYPE } from '../../util/healthProfileConstants';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import AntTinymceInput from './antTinymceInput';
import {
  fetchRaPages,
  fetchRaQuestionPage,
  patchRaQuestionPage,
  postRaQuestionPage,
} from '../../services/raisingAwarenessService';
import RaQuestionOption from './raQuestionOption';
import { CONFLICT, NO_MATCHING_DATA_FOUND } from '../util/utils';

const NEW_OPTION = {
  title: '',
  isTextRequired: null,
  nextQuestionId: null,
  isNokAgreementRequired: null,
  isDecimal: null,
  unit: null,
};

const toRawText = (string) => {
  const div = document.createElement('div');
  div.innerHTML = string;
  return div.innerText;
};

/**
 * NOTE(reo): Collapse의 높이가 불규칙하기 때문에 CollisionDetection 알고리즘을 재정의
 * @see https://docs.dndkit.com/api-documentation/context-provider/collision-detection-algorithms#composition-of-existing-algorithms
 */
function customCollisionDetectionAlgorithm(args) {
  const pointerCollisions = pointerWithin(args);

  if (pointerCollisions.length > 0) {
    return pointerCollisions;
  }

  return closestCenter(args);
}

const DEFAULT_CONTENT = `<h2 class="v3-editor-heading-ra">제목</h2>
<p class="v3-editor-ra-paragraph">설명</p>`;

const RaQuestionForm = ({
  hasAnsweredUser,
  isApp,
  raProjectId,
  pageId,
  variables,
}) => {
  const [questionType, setQuestionType] = useState(null);
  const [nextQuestions, setNextQuestions] = useState([]);
  const [content, setContent] = useState(DEFAULT_CONTENT);
  const [searchInput, setSearchInput] = useState('');
  const [form] = Form.useForm();
  const [editorKey, setEditorKey] = useState(Date.now());

  const getData = useCallback(async () => {
    if (!raProjectId) return;
    const { surveys } = await fetchRaPages(raProjectId);
    setNextQuestions(surveys.map((i) => ({ ...i, title: toRawText(i.title) })));
    if (!pageId) return;
    const res = await fetchRaQuestionPage(raProjectId, pageId);
    form.setFieldsValue(res);
    setContent(res.content);
    setQuestionType(res.type);
  }, [raProjectId, pageId]);

  useEffect(() => {
    getData();
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  const handleDragEnd = ({ active, over }) => {
    if (active?.id !== over?.id) {
      const array = form.getFieldValue('questionOptions');
      const activeIndex = array.findIndex((record) => record.id === active?.id);
      const overIndex = array.findIndex((record) => record.id === over?.id);
      const newArray = arrayMove(
        form.getFieldValue('questionOptions'),
        activeIndex,
        overIndex,
      );
      form.setFieldsValue({
        questionOptions: newArray,
      });
      return newArray;
    }
  };

  const onFinish = async (values) => {
    if (!window.confirm('저장하시겠습니까?')) return;
    const isPatch = !!pageId;

    const data = {
      ...values,
      content,
    };

    if (data.type === 'RADIO' && data.isAnswerRequired === false) {
      notification.error({
        message: '단일 선택 문항은 필수 문항이어야 합니다.',
        key: 'radioAnswerRequiredError',
      });
      return;
    }

    if (data.questionOptions.length > 0) {
      data.questionOptions = data.questionOptions.map((opt) => ({
        title: null,
        isTextRequired: null,
        nextQuestionId: null,
        isDecimal: null,
        unit: null,
        isNokAgreementRequired: null,
        ...opt,
        id: opt.id?.startsWith('NEW?') ? undefined : opt.id,
      }));
    }

    if (
      data.questionOptions?.length === 0 &&
      !['RADIO', 'CHECKBOX', 'DROPDOWN'].includes(questionType)
    ) {
      data.questionOptions = [{}];
    }

    if (questionType === 'CHECKBOX') {
      const nextQuestions = new Map();
      data.questionOptions.forEach((opt) => {
        if (opt.nextQuestionId) {
          nextQuestions.set(opt.nextQuestionId, true);
        }
      });
      if (nextQuestions.size > 1) {
        notification.error({
          message: '복수 선택 문항은 하나의 다음 문항만 지정 가능합니다.',
          key: 'checkboxNextQuestionError',
        });
        return;
      }
    }

    if (data.type === 'NUMBER_INPUT') {
      data.questionOptions[0].isDecimal = !data.questionOptions[0].isDecimal;
    }

    try {
      if (isPatch) {
        await patchRaQuestionPage(raProjectId, pageId, data);
      } else {
        await postRaQuestionPage(raProjectId, data);
      }
      window.alert('저장되었습니다.');
      window.close();
    } catch (e) {
      let description = undefined;
      if (e.data) {
        if (e.data.status === CONFLICT) {
          description =
            'RA 프로그램이 진행중/완료 상태이거나 앱에 노출중이지 않은지 확인해주세요.';
        }
        if (e.data.status === NO_MATCHING_DATA_FOUND) {
          description = '존재하지 않는 질환 또는 페이지입니다.';
        }
      }
      notification.error({
        message: '저장에 실패했습니다.',
        description,
      });
    }
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      name="question"
      onFinish={onFinish}
      form={form}
      initialValues={{
        title: '',
        type: null,
        description: null,
        isAnswerRequired: true,
        questionOptions: [],
      }}
    >
      <Form.Item label="문항 제목 및 설명">
        <AntTinymceInput
          content={content}
          renderKey={editorKey}
          setContent={(c) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(c, 'text/html');
            const title = doc.querySelectorAll('.v3-editor-heading-ra');
            const desc = doc.querySelectorAll('.v3-editor-ra-paragraph');
            let newContent = c;
            console.log(doc.body.childNodes);
            console.log(
              doc.body.childNodes.length === 3 &&
                title.length === 1 &&
                desc.length === 1,
            );
            console.log(doc.body.childNodes.length === 1 && title.length === 1);

            if (
              title.length !== 1 || // 제목이 누락된 경우
              (!(
                doc.body.childNodes.length === 3 &&
                title.length === 1 &&
                desc.length === 1
              ) && // 제목, 설명 외의 것이 있는 경우
                !(doc.body.childNodes.length === 1 && title.length === 1)) // 유일한 노드가 제목이 아닌 경우
            ) {
              notification.warning({
                message: '제목과 설명의 형식을 벗어났습니다.',
                description: '형식을 벗어난 내용을 삭제했습니다.',
                key: 'contentFormatError',
              });
              newContent = `<h2 class="v3-editor-heading-ra">${
                title[0]?.innerHTML || '제목'
              }</h2>`;
              newContent += `<p class="v3-editor-ra-paragraph">${
                desc[0]?.innerHTML || '설명'
              }</p>`;
            }

            // NOTE(reo): newContent가 이전 수정과 같은 값일 경우 AntTinymceInput이 받는 content prop이 같은 값의 string이므로 리렌더링이 발생하지 않음
            // 따라서 key값을 변경하여 리렌더링을 강제함
            setEditorKey(Date.now());
            setContent(newContent);
          }}
          additionalInitOptions={{
            max_height: 320,
            min_height: 320,
          }}
        />
        <Input.TextArea rows={3} value={content} readOnly />
      </Form.Item>
      <Form.Item
        label="답변 타입"
        name="type"
        rules={[
          {
            required: true,
            message: '답변 타입을 선택해주세요.',
          },
        ]}
      >
        <Select
          onChange={(v) => {
            setQuestionType(v);
            form.setFieldsValue({
              questionOptions: [],
            });
          }}
          disabled={hasAnsweredUser || isApp}
        >
          {Object.entries(HEALTH_PROFILE_QUESTION_TYPE).map(([key, value]) => (
            <Select.Option key={key} value={key}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {questionType === 'NUMBER_INPUT' && (
        <Form.Item
          label="단위 문구"
          name={['questionOptions', 0, 'unit']}
          rules={[
            {
              required: true,
              message: '단위 문구를 입력해주세요.',
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}
      <Form.Item
        label="필수 답변"
        name="isAnswerRequired"
        valuePropName="checked"
      >
        <Checkbox>체크 시 응답 필수 / 체크 해제 시 응답 선택</Checkbox>
      </Form.Item>
      {questionType === 'YEAR_INPUT' || questionType === 'NUMBER_INPUT' ? (
        <>
          <Form.Item
            label="답변 조건 값 획득"
            valuePropName="checked"
            name="hasValueSet"
          >
            <Checkbox>
              선택되면 본 답변의 입력 값을 조건 값으로 획득합니다
            </Checkbox>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.hasValueSet !== currentValues.hasValueSet
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('hasValueSet') && (
                <Form.Item
                  label="변수명"
                  name={['questionOptions', 0, 'variableName']}
                  rules={[
                    {
                      required: true,
                      message: '변수명을 입력해주세요.',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ minWidth: 200 }}
                    searchValue={searchInput}
                    onSearch={setSearchInput}
                  >
                    {searchInput.length > 0 &&
                    variables.findIndex((v) => v.includes(searchInput)) ===
                      -1 ? (
                      <Select.Option value={searchInput}>
                        {searchInput}
                      </Select.Option>
                    ) : (
                      <></>
                    )}
                    {variables.map((v) => (
                      <Select.Option key={v} value={v} />
                    ))}
                  </Select>
                </Form.Item>
              )
            }
          </Form.Item>
        </>
      ) : (
        <></>
      )}
      {questionType === 'RADIO' ||
      questionType === 'CHECKBOX' ||
      questionType === 'DROPDOWN' ? (
        <Form.Item label="문항 답변 생성">
          <Form.List
            name="questionOptions"
            rules={[
              {
                validator: async (_, value) => {
                  if (!value || value.length === 0) {
                    return Promise.reject(
                      new Error('1개 이상의 답변을 입력해주세요.'),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => {
              const records = form.getFieldValue('questionOptions');
              return (
                <>
                  <Row style={{ marginBottom: 16 }}>
                    <Button
                      onClick={() => {
                        const newRecord = {
                          ...NEW_OPTION,
                          id: `NEW?${Date.now()}`,
                        };
                        add(newRecord);
                      }}
                      type="primary"
                      disabled={hasAnsweredUser || isApp}
                    >
                      추가
                    </Button>
                  </Row>
                  <DndContext
                    autoScroll={false}
                    modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                    collisionDetection={customCollisionDetectionAlgorithm}
                    onDragEnd={handleDragEnd}
                    sensors={sensors}
                  >
                    <SortableContext
                      items={records?.map((i) => i.id) ?? []}
                      strategy={verticalListSortingStrategy}
                    >
                      {fields.map((field, index) => (
                        <RaQuestionOption
                          key={records[index]?.id}
                          index={index}
                          record={form.getFieldValue([
                            'questionOptions',
                            field.name,
                          ])}
                          remove={remove}
                          nextQuestions={nextQuestions}
                          hasAnsweredUser={hasAnsweredUser || isApp}
                          type={questionType}
                          variables={variables}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                  <Form.ErrorList errors={errors} />
                </>
              );
            }}
          </Form.List>
        </Form.Item>
      ) : (
        <></>
      )}
      {questionType === 'NUMBER_INPUT' && (
        <Form.Item
          label="소수점 비허용"
          name={['questionOptions', 0, 'isDecimal']}
          valuePropName="checked"
        >
          <Checkbox>
            선택되면 본 문항의 답변은 소수점을 허용하지 않습니다.
          </Checkbox>
        </Form.Item>
      )}
      {!['RADIO', 'CHECKBOX', 'DROPDOWN'].includes(questionType) && (
        <Form.Item
          label="다음 문항 지정"
          name={['questionOptions', 0, 'nextQuestionId']}
          help="지정된 질문 ID가 없으면 다음 질문으로 이동합니다. 순서상 이전 문항을 지정하지 않도록 주의해주세요."
          style={{
            marginBottom: 24,
          }}
        >
          <Select
            showSearch
            optionFilterProp="label"
            allowClear
            options={nextQuestions.map((i) => ({
              label: i.title,
              value: i.id,
            }))}
            disabled={hasAnsweredUser || isApp}
          />
        </Form.Item>
      )}
      <Form.Item
        wrapperCol={{
          offset: 4,
          span: 20,
        }}
      >
        <Button type="primary" htmlType="submit" style={{ width: 100 }}>
          저장
        </Button>
        <Button
          htmlType="button"
          style={{ width: 100, marginLeft: 8 }}
          onClick={onReset}
        >
          취소
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RaQuestionForm;
