import React from 'react';
import { PageHeader } from 'antd';

const itemRender = (currentRoute) => {
  return <span>{currentRoute.breadcrumbName}</span>;
};

const TitleBreadcrumb = ({ title, subTitle, extra, pageName }) => {
  const routes = [
    {
      breadcrumbName: title,
    },
    {
      breadcrumbName: subTitle,
    },
  ];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <PageHeader
        className="site-page-header"
        title={`${pageName ?? subTitle} 페이지`}
        breadcrumb={{ routes, itemRender }}
        extra={extra}
      />
    </div>
  );
};

export default TitleBreadcrumb;
