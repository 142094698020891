import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  raProgram: getAsyncState.initial(null),
};

// action types
export const raProgramActions = {
  FETCH_ALL_RA_PROGRAMS: '@raProgram/FETCH_ALL_RA_PROGRAMS',
};

// actions
export const raProgramCreators = {
  fetchAllRaPrograms: createAsyncActions(
    raProgramActions,
    'FETCH_ALL_RA_PROGRAMS',
  ),
};

const raProgramReducer = handleActions(
  {
    ...handleAsyncActions(raProgramActions.FETCH_ALL_RA_PROGRAMS, 'raProgram'),
  },
  initialState,
);

export default raProgramReducer;
