import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const RaQuestionOption = ({
  record,
  index,
  remove,
  nextQuestions,
  hasAnsweredUser,
  type,
  variables,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: record.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const [title, setTitle] = useState(record.title);
  const [hasCondition, setHasCondition] = useState(!!record.variable);
  const [searchInput, setSearchInput] = useState('');
  const isNew = record.id?.startsWith('NEW');
  const titleRef = useRef(null);

  useEffect(() => {
    if (isNew) {
      titleRef.current?.focus();
    }
  }, []);

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={isNew ? record.id : undefined}
      >
        <Collapse.Panel
          header={`${index + 1}번 답변: ${title}`}
          key={record.id}
        >
          <Form.Item label="답변 제목" name={[index, 'title']}>
            <Input
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              ref={titleRef}
            />
          </Form.Item>
          <Form.Item label="답변 조건 값 획득">
            <Checkbox
              checked={hasCondition}
              onChange={(v) => {
                setHasCondition(v.target.checked);
              }}
            >
              선택되면 본 답변의 입력 값을 조건 값으로 획득합니다
            </Checkbox>
            {hasCondition && (
              <Row style={{ gap: 16 }}>
                <Form.Item
                  label="변수명"
                  name={[index, 'answerCondition', 'variable']}
                  rules={[
                    {
                      required: true,
                      message: '변수명을 입력해주세요.',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ minWidth: 200 }}
                    searchValue={searchInput}
                    onSearch={setSearchInput}
                  >
                    {searchInput.length > 0 &&
                    variables.findIndex((v) => v.includes(searchInput)) ===
                      -1 ? (
                      <Select.Option value={searchInput}>
                        {searchInput}
                      </Select.Option>
                    ) : (
                      <></>
                    )}
                    {variables.map((v) => (
                      <Select.Option key={v} value={v} />
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="값"
                  name={[index, 'answerCondition', 'value']}
                  rules={[
                    {
                      required: true,
                      message: '값을 입력해주세요.',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Row>
            )}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.questionOptions[index]?.hasValueSet !==
              currentValues.questionOptions[index]?.hasValueSet
            }
          >
            {({ getFieldValue }) =>
              getFieldValue(['questionOptions', index, 'hasValueSet']) && (
                <Form.Item
                  label="변수명"
                  name={[index, 'variableName']}
                  rules={[
                    {
                      required: true,
                      message: '변수명을 입력해주세요.',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ minWidth: 200 }}
                    searchValue={searchInput}
                    onSearch={setSearchInput}
                  >
                    {searchInput.length > 0 &&
                    variables.findIndex((v) => v.includes(searchInput)) ===
                      -1 ? (
                      <Select.Option value={searchInput}>
                        {searchInput}
                      </Select.Option>
                    ) : (
                      <></>
                    )}
                    {variables.map((v) => (
                      <Select.Option key={v} value={v} />
                    ))}
                  </Select>
                </Form.Item>
              )
            }
          </Form.Item>
          {type === 'RADIO' && (
            <Form.Item
              label="보호자 동의 필요"
              name={[index, 'isNokAgreementRequired']}
              valuePropName="checked"
              disabled={hasAnsweredUser}
            >
              <Checkbox>
                선택되면 본 답변은 만 14세 미만의 경우 보호자의 동의가
                필요합니다
              </Checkbox>
            </Form.Item>
          )}
          {type !== 'DROPDOWN' && (
            <Form.Item
              label="텍스트 입력 필수"
              name={[index, 'isTextRequired']}
              valuePropName="checked"
              disabled={hasAnsweredUser}
            >
              <Checkbox>
                선택되면 본 답변은 텍스트를 입력해야만 다음 문항으로 진행할 수
                있습니다.
              </Checkbox>
            </Form.Item>
          )}
          <Form.Item
            label="다음 문항 지정"
            name={[index, 'nextQuestionId']}
            help="지정된 질문 ID가 없으면 다음 질문으로 이동합니다. 순서상 이전 문항을 지정하지 않도록 주의해주세요."
          >
            <Select
              showSearch
              optionFilterProp="label"
              allowClear
              options={nextQuestions.map((i) => ({
                label: i.title,
                value: i.id,
              }))}
              disabled={hasAnsweredUser}
            />
          </Form.Item>
          <Row justify="end">
            <Button
              onClick={() => {
                if (window.confirm('삭제하시겠습니까?')) {
                  remove(index);
                }
              }}
              danger
              disabled={hasAnsweredUser}
            >
              삭제
            </Button>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default RaQuestionOption;
